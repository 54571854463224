<template>
  <b-card title="Patient Info" class="patient-info">
    <b-form @submit.prevent class="px-2">
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Patient ID"
                label-for="patient_id"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{ patientInfo.patient_id }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Hospital ID"
                label-for="v-hospital-id"
                label-cols-md="4"
              >
                <v-select
                  v-model="hospital"
                  label="hospital_id"
                  :options="hospitals"
                  :clearable="false"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Geburtsdatum"
                label-for="date_of_birth"
                label-cols-md="4"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="date_of_birth"
                    v-model="patientInfo.date_of_birth"
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-mask="dateMask"
                    autocomplete="off"
                    show-decade-nav
                    :readonly="readOnly"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date"
                      value-as-date
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="de"
                      aria-controls="example-input"
                      :disabled="readOnly"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Alter [Jahre]"
                label-for="alter"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.alter"
                  id="alter"
                  type="number"
                  placeholder="Alter"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Geschlecht"
                label-for="geschlecht"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.geschlecht"
                  :options="geschlechts"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Größe [cm]"
                label-for="groesse_cm"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.groesse_cm"
                  id="groesse_cm"
                  type="number"
                  placeholder="Größe"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Gewicht (kg)"
                label-for="gewicht_kg"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.gewicht_kg"
                  id="gewicht_kg"
                  type="number"
                  placeholder="Gewicht"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="BMI" label-for="bmi" label-cols-md="4">
                <div class="vertical-align">
                  {{ bmi }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Hauptdiagnose"
                label-for="hauptdiagnose"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.hauptdiagnose"
                  id="hauptdiagnose"
                  type="text"
                  placeholder="Hauptdiagnose"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Aufnahmedatum"
                label-for="aufnahmedatum_date"
                label-cols-md="4"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="aufnahmedatum_date"
                    v-model="patientInfo.aufnahmedatum_date"
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-mask="dateMask"
                    autocomplete="off"
                    show-decade-nav
                    :readonly="readOnly"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date1"
                      value-as-date
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="de"
                      aria-controls="example-input"
                      :disabled="readOnly"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { convertDate2Str, dateMask } from "@core/utils/utils";
import { mapState, mapActions } from "vuex";
import config from "@/config";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  timers: {
    updatePatient: config.TIMER_OPTION,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        hospital_id: null,
        // first_name: '',
        // last_name: '',
        date_of_birth: "",
        alter: "",
        geschlecht: "",
        groesse_cm: "",
        gewicht_kg: "",
        hauptdiagnose: "",
        aufnahmedatum_date: "",
        // city: '',
        // zip_code: '',
        // street: '',
        // house_number: '',
        // phone: '',
        // email: '',
      },
      hospital: null,
      hospitals: [],
      geschlechts: ["mannlich", "weiblich"],
      birth: "",
      gender: "",
      date: null,
      date1: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    bmi() {
      if (!this.patientInfo.groesse_cm) {
        this.patientInfo.bmi = "0.00";
      } else {
        this.patientInfo.bmi = (
          this.patientInfo.gewicht_kg /
          Math.pow(this.patientInfo.groesse_cm / 100, 2)
        ).toFixed(2);
      }
      return this.patientInfo.bmi;
    },
  },
  watch: {
    date(v, ov) {
      if (v) {
        this.patientInfo.date_of_birth = convertDate2Str(v);
      }
    },
    date1(v, ov) {
      if (v) {
        this.patientInfo.aufnahmedatum_date = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    hospital(v, ov) {
      if (v && v._id) {
        this.patientInfo.hospital_id = v._id;
      }
    },
  },
  mounted() {
    this.fetchHospitals();
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  // beforeDestroy() {
  //   this.updatePatient()
  // },
  methods: {
    dateMask,
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1
      ) {
        return;
      }

      this.updatePatientById(this.patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    fetchHospitals() {
      this.$http
        .get("/hospitals/list_for_update")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    handleSave() {
      this.$http
        .patch(`/patients/update/${this.$route.params.id}`, this.patientInfo)
        .then((res) => {
          this.showToast("success", "Success", "Successfully updated.");
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while saving the patient."
          );
        });
    },
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.patient_id = v.patient_id;
      if (v.hospital) {
        this.hospital = { hospital_id: v.hospital.hospital_id };
        this.patientInfo.hospital_id = v.hospital._id;
      } else {
        this.hospital = null;
        this.patientInfo.hospital_id = null;
      }
      this.patientInfo.date_of_birth = v.date_of_birth;
      this.patientInfo.alter = v.alter;
      this.patientInfo.geschlecht = v.geschlecht;
      this.patientInfo.groesse_cm = v.groesse_cm;
      this.patientInfo.gewicht_kg = v.gewicht_kg;
      this.patientInfo.bmi = v.bmi;
      this.patientInfo.hauptdiagnose = v.hauptdiagnose;
      this.patientInfo.aufnahmedatum_date = v.aufnahmedatum_date;
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.patient-info {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
